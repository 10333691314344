import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { SchedulerLoginUser } from '../../types/scheduler/scheduler-login-user.type';
import { SchedulerLoginUserGroup } from '../../types/scheduler/scheduler-login-user-group.type';
import { BaseEntity } from '../../types/base-entity.type';

@Injectable({ providedIn: 'root' })
export class SchedulerLoginUsersApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/scheduler-login-users`;
  }

  async getLoginUsers(): Promise<SchedulerLoginUser[]> {
    return await this.get<SchedulerLoginUser[]>('');
  }

  async getLoginUserList(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('list');
  }

  async createLoginUser(user: SchedulerLoginUser): Promise<string> {
    return await this.postWithResult('', user);
  }

  async updateLoginUser(user: SchedulerLoginUser): Promise<void> {
    return await this.put('', user);
  }

  async assignLoginUserToGroups(id: string, groupIds: string[]): Promise<void> {
    return await this.patch(`${id}/groups`, groupIds);
  }

  async deleteLoginUser(id: string): Promise<void> {
    return await this.delete(id);
  }

  async getLoginUserGroups(): Promise<SchedulerLoginUserGroup[]> {
    return await this.get<SchedulerLoginUserGroup[]>('groups');
  }

  async getLoginUserGroupList(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('groups/list');
  }

  async createLoginUserGroup(group: SchedulerLoginUserGroup): Promise<string> {
    return await this.postWithResult('groups', group);
  }

  async updateLoginUserGroup(group: SchedulerLoginUserGroup): Promise<void> {
    return await this.put(`groups`, group);
  }

  async deleteLoginUserGroup(id: string): Promise<void> {
    return await this.delete(`groups/${id}`);
  }
}
