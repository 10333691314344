import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { OrganizationUser } from '../../types/organization-user.type';
import { UpdateOrganizationModel } from '../../types/organization.type';
import { Region } from '../../types/region.type';
import { BaseApiService } from './base-api.service';
import { ResetPasswordResult } from '../../types/set-password-result.type';

@Injectable({ providedIn: 'root' })
export class OrganizationSettingsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/organization-settings`;
  }

  async updateOrganizationSettings(organization: UpdateOrganizationModel): Promise<void> {
    ApiConversionHelper.convertTimeForApi(organization, ['businessHoursFrom', 'businessHoursTo']);
    ApiConversionHelper.convertTimeSpansForApi(organization.orchestration, ['schedulerPeriod', 'uiPathPartialSynchronizationPeriod']);
    await this.patch('', organization);
  }

  async updateOrganizationUser(user: OrganizationUser): Promise<void> {
    return await this.put(`users/${user.id}`, user);
  }

  async getOrganizationLicenseCount(): Promise<number> {
    return await this.get<number>('license-count');
  }

  async getOrganizationSupportedRegions(): Promise<Region[]> {
    return await this.get<Region[]>('supported-regions');
  }

  async getOrganizationUsers(): Promise<OrganizationUser[]> {
    const result = await this.get<OrganizationUser[]>('users');
    ApiConversionHelper.convertDates(result, ['lastLogin']);
    return result;
  }

  async createOrganizationUser(user: OrganizationUser): Promise<ResetPasswordResult> {
    return await this.postWithResult('users', user);
  }

  async deleteOrganizationUser(id: string): Promise<void> {
    return await this.delete(`users/${id}`);
  }
}
