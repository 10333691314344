<div class="form-control chip-input" [class.disabled]="disabled" (keydown)="onKeyDown($event)">
  <mat-chip-grid #chipList aria-label="chip selection" class="chip-content">
    @for (chip of chips; track chip; let i = $index) {
      <mat-chip [selectable]="selectable" [removable]="removable" (removed)="remove(chip)">
        {{ chip }}
        @if (removable) {
          <svg-icon key="close" size="xs" matChipRemove></svg-icon>
        }
      </mat-chip>
    }
    <input
      #chipInput
      [formControl]="chipCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      [readOnly]="maxCount && maxCount <= chips.length"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="chip-autocomplete">
    @for (chip of filteredChips$ | async; track chip) {
      <mat-option [value]="chip">
        {{ chip }}
      </mat-option>
    }
  </mat-autocomplete>
</div>
