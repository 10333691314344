import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'shared-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ChipsInputComponent }],
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor {
  @Input() chipList: string[];
  @Input() maxCount?: number;

  @ViewChild('chipInput', { static: true }) chipInput: ElementRef;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  chips: string[] = [];
  separatorKeysCodes: number[] = [ENTER, SPACE, COMMA];
  chipCtrl = new FormControl();
  filteredChips$: Observable<string[]>;

  private onChange = (values: string[]): void => {};
  private onTouched = (): void => {};
  private touched = false;
  disabled = false;

  ngOnInit(): void {
    this.filteredChips$ = this.chipCtrl.valueChanges.pipe(
      map((chip: string) => (chip ? this.filter(chip) : this.chipList.filter(i => !this.chips.includes(i)))),
      debounceTime(300),
      startWith(this.chipList.slice()),
    );
  }

  add(event: MatChipInputEvent): void {
    // Add chip only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.chipInput;
      const value = event.value;

      // Add chip if chip.length < maxCount
      if (this.maxCount && this.chips.length >= this.maxCount) {
        return;
      }
      const trimmedValue = (value || '').trim();
      if (trimmedValue && this.chips.includes(trimmedValue) === false) {
        this.chips.push(trimmedValue);
      }

      // Reset the input value
      if (input) {
        input.inputElement.value = '';
      }

      this.chipCtrl.setValue(null);
      this.onChange(this.chips);
    }
  }

  remove(chip: string): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
      this.onChange(this.chips);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.chips.includes(event.option.viewValue) === false && (!this.maxCount || this.chips.length < this.maxCount)) {
      this.chips.push(event.option.viewValue);
      this.onChange(this.chips);
    }
    (this.chipInput.nativeElement as HTMLInputElement).value = '';
    this.chipCtrl.setValue(null);
  }

  private filter(value: string): string[] {
    if (!this.chipList) {
      return [];
    }

    const filterValue = value.toLowerCase();
    return this.chipList.filter(i => i.toLowerCase().startsWith(filterValue) && !this.chips.includes(i));
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace' && !(this.chipInput.nativeElement as HTMLInputElement).value && this.chips.length) {
      this.removeLastChip();
    }

    // submit on Ctrl + Enter
    if (event.ctrlKey && event.key === 'Enter') {
      this.onChange(this.chips);
    }
  }

  private removeLastChip(): void {
    this.chips.pop();
  }

  writeValue(values: string[]): void {
    this.chips = values;
  }

  registerOnChange(onChange: (values: string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    if (this.disabled) {
      this.chipCtrl.disable();
    } else {
      this.chipCtrl.enable();
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
