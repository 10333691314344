@if (form) {
  <form [formGroup]="form">
    <div class="inline-content">
      <shared-calendar-dropdown name="date" formControlName="date" [minDate]="minDate" [maxDate]="maxDate"></shared-calendar-dropdown>
      @if (dateTimeDelimiter) {
        <span>{{ dateTimeDelimiter }}</span>
      }
      @if (!f.time.disabled) {
        <shared-time-picker name="time" formControlName="time"></shared-time-picker>
      }
    </div>
    @if (form.invalid && (form.dirty || form.touched)) {
      <mat-error class="error-message">Invalid date and time</mat-error>
    } @else if (form.controls.date.errors?.required) {
      <div class="error-message">Date is required</div>
    } @else if (form.controls.date.errors?.dateOutOfRange) {
      <div class="error-message">Date out of range</div>
    } @else if (form.controls.time.errors?.required) {
      <div class="error-message">Time is required</div>
    } @else if (form.errors?.invalidTime) {
      <mat-error class="error-message">Time is invalid</mat-error>
    }
  </form>
}
