<shared-modal [modalTitle]="'Cost of manual processing'">
  <ng-container modalBody>
    <div class="body-content">
      <form [formGroup]="form" (ngSubmit)="setManualProcessingCost()">
        <p>Enter how much it costs to manually process one {{ 'queue-item' | platformTexts}} to find out the cost of exceptions in this process.</p>
        <div class="form-group">
          <div class="currency-input-wrapper">
            <div class="currency-input">
              <input
                name="manual-processing-cost"
                formControlName="manualProcessingCost"
                aria-label="Cost of manual processing"
                class="form-control text-end"
                mask="separator.2"
                thousandSeparator=","
                maxlength="26"
                autofocus-select
                />
                <span class="currency-symbol">{{ organizationCurrency }}</span>
              </div>
              <label class="currency-input-label">per item</label>
            </div>

            @if (errorMessage && errorMessage.length > 0) {
              <div class="alert alert-danger border border-0">{{errorMessage}}</div>
            }
            @if (displayReset && !submitted) {
              <button type="button" (click)="resetManualProcessingCost()" class="btn btn-link"><svg-icon key="undo"></svg-icon>Reset to unknown</button>
            }
          </div>
          <div class="btn-group-flex">
            <button type="button" (click)="close()" class="btn btn-cancel">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Save</button>
          </div>
        </form>
      </div>
    </ng-container>
  </shared-modal>
