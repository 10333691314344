import { SchedulerActionParameter, SchedulerActionParameterValue } from '../types/scheduler/scheduler-task.type';

export class SchedulerParametersHelper {
  static combineParameters(definitions: SchedulerActionParameter[], values: SchedulerActionParameterValue[]): SchedulerActionParameterValue[] {
    const parameters = definitions?.map(p => ({ name: p.name , value: values?.find(v => v.name === p.name)?.value ?? '' })) ?? [];
    return parameters;
  }

  static getValues(parameters: SchedulerActionParameter[]): SchedulerActionParameterValue[] {
    return parameters?.map(p => ({ name: p.name, value: p.value })) ?? [];
  }

  static removeEmptyValues(values: SchedulerActionParameterValue[]): SchedulerActionParameterValue[] {
    return values?.filter(v => v.value != '' && v.value != null) ?? [];
  }
}
