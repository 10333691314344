export enum SessionParameterType {
  Date = 'date',
  DateTime = 'datetime',
  Flag = 'flag',
  Number = 'number',
  Password = 'password',
  Text = 'text',
  Time = 'time',
  TimeSpan = 'timespan',
}
