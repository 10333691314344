import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'shared-time-span',
  templateUrl: './time-span.component.html',
  styleUrls: ['./time-span.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimeSpanComponent }],
})
export class TimeSpanComponent implements ControlValueAccessor, OnInit {
  timeSpanForm: FormGroup = this.fb.group({
    days: [0, [Validators.min(0), Validators.max(99)]],
    hours: [0, [Validators.min(0), Validators.max(23)]],
    minutes: [0, [Validators.min(0), Validators.max(59)]],
    seconds: [0, [Validators.min(0), Validators.max(59)]],
  });
  value: any;
  isDisabled: boolean = false;
  private onChange: (value: number) => void = () => {};
  private onTouched: any = () => {};

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.timeSpanForm.valueChanges.subscribe(value => {
      const totalSeconds = (value.days ?? 0) * 86400 + (value.hours ?? 0) * 3600 + (value.minutes ?? 0) * 60 + (value.seconds ?? 0);
      this.onChange(totalSeconds);
      this.onTouched();
    });
  }

  writeValue(value: number): void {
    if (value !== undefined) {
      const days = Math.floor(value / 86400);
      const hours = Math.floor((value % 86400) / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = value % 60;
      this.timeSpanForm.setValue({ days, hours, minutes, seconds }, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeSpanForm.disable();
    } else {
      this.timeSpanForm.enable();
    }
  }
}
