import { AfterViewInit, ApplicationRef, ComponentFactoryResolver, Directive, ElementRef, Injector, SimpleChange } from '@angular/core';
import { TooltipDirective } from '@cloudfactorydk/ng2-tooltip-directive';
import { tooltipOptions } from '../shared.module';

@Directive({ selector: '[labelTooltip]' })
export class LabelTooltipDirective extends TooltipDirective implements AfterViewInit {
  private el: ElementRef;

  constructor(elementRef: ElementRef, componentFactoryResolver: ComponentFactoryResolver, appRef: ApplicationRef, injector: Injector) {
    super(tooltipOptions, elementRef, componentFactoryResolver, appRef, injector);
    this.el = elementRef;
  }

  ngAfterViewInit(): void {
    const element = this.el.nativeElement as HTMLElement;
    if (this.tooltipValue == null && element.innerText.length > 0) {
      this.tooltipValue = element.innerText;
      this.ngOnChanges({
        tooltipValue: new SimpleChange(null, this.tooltipValue, true),
      });
    }
  }

  onMouseEnter(): void {
    const element = this.el.nativeElement as HTMLElement;
    const previousValue = this.display;
    this.display
      = element != null && element.scrollWidth > 0
        ? element.scrollWidth > element.offsetWidth
        : element.parentElement.offsetWidth > 0
          ? element.parentElement.offsetWidth < element.offsetWidth
          : false;

    if (this.display !== previousValue) {
      const changes = {
        display: new SimpleChange(previousValue, this.display, false),
      };

      this.ngOnChanges(changes);
    }

    super.onMouseEnter();
  }
}
