import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DateHelper, DateTimeUnit } from '../../helpers/date.helper';
import { Subscription } from 'rxjs';
import { FormHelper } from '../../helpers/form.helper';

export function controlProviderFactory(container: ControlContainer) {
  return container;
}

@Component({
  selector: 'shared-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DateTimePickerComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: DateTimePickerComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent implements ControlValueAccessor, OnDestroy, OnInit, Validator {
  @Input() dateTimeDelimiter = 'at';
  @Input() displayTime = true;
  @Input() minDate: Date = DateHelper.addTime(DateHelper.endOfYear(new Date()), -10, DateTimeUnit.Year);
  @Input() maxDate: Date = DateHelper.addTime(DateHelper.endOfYear(new Date()), 50, DateTimeUnit.Year);

  form = this.formBuilder.group<DateTimeControls>(
    {
      date: new FormControl<Date | null>(null, { nonNullable: true, validators: [this.dateValidator.bind(this)] }),
      time: new FormControl({ value: null, disabled: !this.displayTime }, { nonNullable: true, validators: [this.timeValidator.bind(this)] }),
    },
    { validators: [this.dateTimeRequiredValidator.bind(this)] }
  );

  disabled: boolean;
  private onChange = (dateTime: Date) => {};
  private onTouched = () => {};
  private onValidationChange: () => void;
  private touched = false;
  private subscription: Subscription;

  get f() {
    return this.form.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.calculateDateTime();
    });
    this.form.get('time')?.statusChanges.subscribe(() => {
      if (!this.displayTime) {
        this.form.get('time')?.disable({ emitEvent: false });
      } else {
        this.form.get('time')?.enable({ emitEvent: false });
      }
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  writeValue(dateTime: Date): void {
    if (!dateTime) {
      this.form.reset();
      this.cdr.markForCheck();
      return;
    }

    const { date, time } = DateHelper.splitDateTime(dateTime);
    this.form.setValue(
      {
        date: date,
        time: time,
      },
      { emitEvent: false }
    );
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (dateTime: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidationChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  private calculateDateTime() {
    this.markAsTouched();
    const { date, time } = this.form.value;
    if (time == null) {
      this.onChange(date);
      return;
    }
    const dateTime = DateHelper.joinDateTime(date, time);
    this.onChange(dateTime);
  }

  private dateValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value == null) {
      return null;
    }
    const date = new Date(control.value);
    const startDate = this.minDate;
    const endDate = this.maxDate;
    if (date != null && (date < startDate || date > endDate)) {
      return { dateOutOfRange: true };
    }
    return null;
  }

  private timeValidator(control: AbstractControl): ValidationErrors | null {
    const time: number = control.value;
    if (time == null) {
      return null;
    }
    if (typeof time !== 'number' || isNaN(time)) {
      return { invalidTime: true };
    }
    if (time < 0 || time > 86399000) {
      // 23 hours and 59 minutes and 59 seconds in milliseconds
      return { invalidTime: true };
    }
    return null;
  }

  private dateTimeRequiredValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || !this.displayTime) {
      return null;
    }

    const { date, time } = control.value;
    if (date == null && time != null) {
      control.get('date')?.setErrors({ required: true });
      return { required: true };
    } else if (date != null && time == null) {
      control.get('time')?.setErrors({ required: true });
      return { required: true };
    }
    return null;
  }
}

export interface DateTimeControls {
  date: FormControl<Date>;
  time: FormControl<number>;
}
