import { DayOfWeek, ExceptionSource, FeatureFlags, ManagementMetricType, Permission, Platform, ResourceLoginType, SavingsUnit } from '../enums/generated.enums';
import { CurrencyCode } from '../helpers/currency.helper';
import { Domain } from './domain.type';
import { SchedulerActionParameter } from './scheduler/scheduler-task.type';

export class Organization {
  id: string;
  name: string;
  label: string;
  configuration: OrganizationConfiguration;
  permissions: Permission[] = [Permission.Monitoring];
  featureFlags: FeatureFlags[] = [];
  users: { email: string; firstName: string; surname: string }[];
  hasLogo: boolean;
  domains: Domain[];
  tenantIds: string[];
  secretCode: string;
}

export class DefaultOrganizationConfiguration {
  static pricePerHour = 10;
  static currencyCode: CurrencyCode = CurrencyCode.EUR;
  static currencySymbol = '€';
}

export class OrganizationConfiguration {
  bluePrismScheduler?: BluePrismSchedulerConfiguration = null;
  businessHoursFrom: number;
  businessHoursTo: number;
  currencyCode: CurrencyCode = DefaultOrganizationConfiguration.currencyCode;
  currencySymbol = DefaultOrganizationConfiguration.currencySymbol;
  emulator: EmulatorConfiguration = null;
  externalContainerSas: string;
  fteHoursPerYear?: number = null;
  generateMonthlyReports = false;
  healthCheckUploadPeriodHours?: number = null;
  healthCheckPredictionPeriodHours?: number = null;
  ianaTimeZoneId?: string;
  incidentDetectionEnabled: boolean;
  is24HFormat: boolean;
  firstDayOfWeek: DayOfWeek;
  isBusinessExceptionSuccess = true;
  // isSafeToDeleteData: boolean;
  isUserPasswordLoginDisabled: boolean = null;
  itemBusinessExceptionRegexPattern = 'Business Exception';
  itemExceptionSource = ExceptionSource.Tag;
  orchestration: OrchestrationConfiguration = null;
  platforms = [Platform.BluePrism];
  // ***** Organization Snapshot *****
  pricePerHour = DefaultOrganizationConfiguration.pricePerHour;
  fteCount?: number;
  rpaFteCount?: number;
  rpaDevelopersFteCount?: number;
  rpaOperationsFteCount?: number;
  rpaFteYearlyCost?: number;
  bluePrismYearlyCost?: number;
  infrastructureYearlyCost?: number;
  singleVdiYearlyCost?: number;
  additionalRpaToolsYearlyCost?: number;
  internalOverheadYearlyCost?: number;
  // ***** end of Organization Snapshot *****
  queueRegexPattern: string;
  reporting?: ReportingConfiguration = null;
  rpaProcessRegexPattern: string;
  savingsUnit: SavingsUnit = SavingsUnit.FTE;
  sla?: number = null; // percentage
  synchronizeFromOnPrem = false;
  cloudUploaderEnabled = true;
  timeZoneId: string;
  uiPathApi: UiPathApiCredentials;
  uiPathOldApi: UiPathOldApiCredentials;
  // windowsTimeZoneId: 'Central Europe Standard Time';
  services: Service[];
}

export interface BluePrismSchedulerConfiguration {
  bluePrismConnectionName: string;
  bluePrismConnectionString: string;
  bluePrismCommandTimeout: number;
  automateCPath: string;
  automateCAuthorizationParameter: string;
  isOldBluePrismVersion: boolean;
}

export interface OrchestrationConfiguration {
  licenseCount: number;
  nationalHolidays: string[];
  loginProcessId: string;
  logoutProcessId: string;
  preferredLoginType: ResourceLoginType;
  shouldCreateSchedulerDump: boolean;
  shouldRestartResourcesIfUnavailable: boolean;
  priorityThresholdForStopSession: number;
  maxRunNowPriority: number;
  maxPriority: number;
  schedulerPeriod: number;
  schedulerSpeedWarningThreshold: number;
  uiPathPartialSynchronizationPeriod?: number;
}

export interface EmulatorConfiguration {
  licenseCount: number;
  nationalHolidays: string[];
}

export interface ReportingConfiguration {
  hiddenMetrics: ManagementMetricType[];
  hiddenMetricsPerRole: Record<string, ManagementMetricType[]>;
}

export interface UpdateOrganizationModel {
  pricePerHour: number;
  fteCount?: number;
  rpaFteCount?: number;
  rpaDevelopersFteCount?: number;
  rpaOperationsFteCount?: number;
  rpaFteYearlyCost?: number;
  bluePrismYearlyCost?: number;
  infrastructureYearlyCost?: number;
  singleVdiYearlyCost?: number;
  additionalRpaToolsYearlyCost?: number;
  internalOverheadYearlyCost?: number;
  isBusinessExceptionSuccess: boolean;
  businessHoursFrom?: number;
  businessHoursTo?: number;
  is24HFormat: boolean;
  firstDayOfWeek: DayOfWeek;
  rpaProcessRegexPattern: string;
  queueRegexPattern: string;
  itemExceptionSource: ExceptionSource;
  itemBusinessExceptionRegexPattern: string;
  savingsUnit: SavingsUnit;
  orchestration?: OrchestrationConfiguration;
  emulator?: EmulatorConfiguration;
  bluePrismScheduler?: BluePrismSchedulerConfiguration;
  reporting?: ReportingConfiguration;
  fteHoursPerYear?: number;
  uiPathApi?: UiPathApiCredentials;
  uiPathOldApi?: UiPathOldApiCredentials;
}

export class UiPathApiCredentials {
  baseUrl: string;
  applicationId: string;
  applicationSecret: string;
}

export class UiPathOldApiCredentials {
  baseUrl: string;
  tenantName: string;
  userName: string;
  password: string;
}

export interface Service {
  id: string;
  label: string;
  type: string;
}

export interface ImapServiceConfiguration extends Service {
  host: string;
  port: number;
  username: string;
  useSsl: boolean;
  password: string;
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

export interface SharePointServiceConfiguration extends Service {
  sharePointUrl: string;
  tenantId: string;
  clientId: string;
  clientSecret: string;
}
