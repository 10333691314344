<div class="interval-dropdown">
  <button type="button" [dropdownTriggerFor]="dropdown" [disabled]="disabled">
    <svg-icon key="calendar" class="calendar-icon"></svg-icon>
    <p class="label">{{ selectedDateLabel }}</p>
    <span class="chevron-icon">
      <svg-icon key="arrow-button-down"></svg-icon>
    </span>
  </button>
</div>
<app-dropdown #dropdown [closeOnClick]="false">
  <div class="calendar-dropdown-container">
    <div class="inline-calendar-card">
      <mat-calendar
        #calendar
        [selected]="selectedDateTime"
        [minDate]="minDateTime"
        [maxDate]="maxDateTime"
        [dateFilter]="dateFilter"
        [startAt]="selectedDateTime"
        [headerComponent]="customCalendarHeader"
        (selectedChange)="dateChanged($event)"
      ></mat-calendar>
    </div>
  </div>
</app-dropdown>
