import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef, TemplateRef, OnDestroy, ComponentRef, EnvironmentInjector } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { LoadingScreenService } from './loading-screen.service';
import { LoggingService } from './logging.service';
import { NavigationService } from './navigation.service';

export interface SidePanelOptions {
  size?: 'small' | 'medium' | 'large' | 'auto';
  autosize?: boolean;
  hasBackdrop?: boolean;
  hasCloseButton?: boolean;
  cancelOnRouteChange?: boolean;
  showLoadingScreen?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SidepanelService implements OnDestroy {
  private readonly defaultOptions: SidePanelOptions = {
    size: 'auto',
    autosize: true,
    hasBackdrop: false,
    hasCloseButton: false,
    cancelOnRouteChange: false,
    showLoadingScreen: true,
  };

  private panel: MatSidenav;
  private vcf: ViewContainerRef;
  private cmpRef: ComponentRef<any>;
  private _settings = { ...this.defaultOptions };
  private subscription: Subscription;

  get isOpened(): boolean {
    return this.panel.opened;
  }

  get closed$(): Observable<boolean> {
    return this.panel.openedChange.pipe(filter(opened => !opened));
  }

  get settings(): SidePanelOptions {
    return this._settings;
  }

  constructor(private router: Router, private navigationService: NavigationService, private loggingService: LoggingService, private loadingScreenService: LoadingScreenService) {}

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  setPanel(sidenav: MatSidenav) {
    this.panel = sidenav;
  }

  setContentVcf(viewContainerRef: ViewContainerRef) {
    this.vcf = viewContainerRef;
  }

  openTemplate(template: TemplateRef<any>) {
    this.vcf.clear();
    this.vcf.createEmbeddedView(template);
    return this.panel.open();
  }

  // use environmentInjector if you need to use providers overides
  async open<T>(component: ComponentType<T>, options: SidePanelOptions, setParameters?: (d: T) => void, environmentInjector?: EnvironmentInjector): Promise<void> {
    try {
      await this.openInternal(component, options, setParameters, environmentInjector);
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  private async openInternal<T>(component: ComponentType<T>, options: SidePanelOptions, setParameters?: (d: T) => void, environmentInjector?: EnvironmentInjector): Promise<void> {
    this._settings = { ...this.defaultOptions, ...options };

    if (this.settings.showLoadingScreen === true) {
      this.loadingScreenService.showLoadingScreen();
    }

    this.setScrollableBody(false);

    try {
      if (this.panel.opened) {
        await this.panel.close();
      }

      this.vcf.clear();
      const previousComponentName = this.cmpRef?.instance?.constructor.name;
      if (this.cmpRef != null) {
        this.cmpRef.destroy();
        this.cmpRef = null;
      }

      const componentName = component.prototype.constructor.name;
      if (previousComponentName === componentName) {
        this.setScrollableBody(true);
        return;
      }

      if (this._settings.cancelOnRouteChange) {
        this.subscription = this.navigationService.previousUrl$.subscribe(async (previousUrl: string) => {
          if (this.panel.opened) {
            if (this.router.url.split('#')[0] !== previousUrl?.split('#')[0]) {
              await this.close();
            }
          }
        });
      }

      this.cmpRef = this.vcf.createComponent(component, { environmentInjector });

      // Apply the parameters into the component
      if (setParameters) {
        setParameters(this.cmpRef.instance);
      }

      this.vcf.insert(this.cmpRef.hostView);

      // wait for the component to be initialized
      if (this.cmpRef.instance.initialize) {
        await this.cmpRef.instance.initialize();
      }

      await this.panel.open();
    } catch {
      this.setScrollableBody(true);
    } finally {
      this.loadingScreenService.hideLoadingScreen();
    }
  }

  async close(): Promise<void> {
    if (this.cmpRef?.instance?.onClosing != null) {
      if ((await this.cmpRef.instance.onClosing()) === false) {
        return;
      }
    }

    this.cmpRef?.destroy();
    this.cmpRef = null;
    this.subscription?.unsubscribe();
    this.subscription = null;

    if (this.panel?.opened) {
      await this.panel.close();
    }

    this.setScrollableBody(true);
  }

  toggle() {
    return this.panel.toggle();
  }

  scrollToTop() {
    this.panel._container.scrollable?.scrollTo({ top: 0 });
  }

  private setScrollableBody(value: boolean) {
    document.body.style.overflowY = value ? 'auto' : 'hidden';
  }
}
