import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectCheckAllComponent implements OnInit, OnDestroy {
  @Input() model: AbstractControl;
  @Input() values: any[] = [];
  @Input() text = 'Select All';

  @ViewChild(MatOption, { static: true }) option: MatOption;

  private subscription: Subscription;

  ngOnInit(): void {
    if (this.model == null) {
      return;
    }

    const allSelected = this.model?.value.length === this.values?.length;
    if (allSelected) {
      this.option.select();
    } else {
      this.option.deselect();
    }

    this.subscription = this.model.valueChanges.subscribe(value => {
      const allSelected = value?.length === this.values?.length;
      if (allSelected) {
        this.option.select();
      } else {
        this.option.deselect();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  toggleSelection(checked: boolean): void {
    if (checked) {
      this.model.setValue(this.values);
    } else {
      this.model.setValue([]);
    }
  }
}
