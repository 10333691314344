import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, TimeScaleOptions, TooltipItem } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { FormatHelper } from 'src/app/shared/helpers/format.helper';
import { DateRange } from 'src/app/shared/types/date-range.type';
import { ChartColor } from '../chart-color.enum';
import { getWeekendsAnnotations } from '../plugins/highlight-weekends.plugin';
import { legendMarginPlugin } from '../plugins/legend-margin.plugin';
import { ScatterChartData } from './scatter-chart-data.type';

@Component({
  selector: 'app-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScatterChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: ScatterChartData;
  @Input() annotations: AnnotationOptions[];

  private chart: Chart;

  @ViewChild('scatterChart', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  constructor() {}

  ngOnInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.updateChart();
    }
  }

  private initializeChart(): void {
    const daysCount = DateHelper.getDaysCount(this.data.from, this.data.to);
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [],
      },
      plugins: [legendMarginPlugin],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x',
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawTicks: false,
            },
            ticks: {
              autoSkip: true,
              padding: 8,
              callback: (value, index, values) => {
                return FormatHelper.format(value, this.data.formatType, false, true, this.data.unit);
              },
            },
            border: {
              display: true,
              dash: [8, 4],
              dashOffset: 10,
            },
            min: 0,
          },
          x: {
            type: 'time',
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
            },
            border: {
              display: true,
              color: ChartColor.Border,
            },
            position: 'bottom',
            ticks: {
              padding: 8,
              maxTicksLimit: 16,
              autoSkipPadding: 8,
              maxRotation: 30,
              minRotation: 30,
            },
            min: this.data.from.getTime(),
            max: this.data.to.getTime(),
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            boxPadding: 4,
            displayColors: true,
            callbacks: {
              title: (tooltipItems: TooltipItem<'line'>[]) => {
                return DateHelper.formatDate(new Date(tooltipItems[0].parsed.x), DateHelper.fullDateTimeFormat);
              },
              label: (context: TooltipItem<'line'>) => {
                return FormatHelper.format(context.parsed.y, this.data.formatType, false, true, this.data.unit);
              },
            },
          },
          filler: {
            propagate: false,
          },
          annotation: {
            interaction: {
              intersect: true,
            },
            annotations: [],
          },
        },
        onResize: (c, s) => ((c.options.scales.x as TimeScaleOptions).ticks.labelOffset = s.width > 0 ? (s.width - 76) / daysCount / 2 : 0),
      },
    });

    this.updateChart();
  }

  private updateChart(): void {
    if (!this.chart) {
      return;
    }

    this.chart.data.datasets =
      this.data.lines?.map(t => ({
        type: 'line',
        data: t,
        borderColor: this.data.lineColor,
        backgroundColor: this.data.lineColor,
        fill: false,
        pointRadius: 0,
      })) ?? [];

    this.chart.data.datasets.push({
      type: 'scatter',
      data: this.data.points,
      borderColor: this.data.pointColor,
      backgroundColor: this.data.pointColor,
    });

    this.refreshAnnotations();
    this.chart.update();
  }

  private refreshAnnotations() {
    if (this.chart?.options?.plugins?.annotation == null) {
      return;
    }
    const annotations = this.annotations ?? [];
    const weekendsAnnotations = getWeekendsAnnotations(new DateRange(this.data.from, this.data.to));
    this.chart.options.plugins.annotation.annotations = annotations.concat(weekendsAnnotations);
  }
}
